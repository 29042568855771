import axios from "axios";

export const list = (params, page, pageSize) => {
  return axios.get("/geomagnetic/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/geomagnetic/add", vo);
};

export const detail = id => {
  return axios.get(`/geomagnetic/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/geomagnetic/${id}/edit`, vo);
};

export const deleteGeomagnetic = id => {
  return axios.delete(`/geomagnetic/${id}/del`);
};